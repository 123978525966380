/**
 * The purpose of this file is to override localStorage to allow a user
 * to be signed in to multiple accounts at once. This is done by prepending
 * the account name to all recieved localStorage keys.
 * Eg. "persist:session" becomes "API_develop:persist:session"
 *
 * Mutliple accounts can be signed into by going to `/#/login`
 *
 * This is not done for arrigo local.
 */
import API from "eos/context/api"
const _localStorageGet = Storage.prototype.getItem
const _localStorageSet = Storage.prototype.setItem
const _localStorageRemove = Storage.prototype.removeItem
const CURRENT_ACCOUNT_KEY = "currentAccount"
const LOGIN_KEY = "login"

function getAccount() {
  if (sessionStorage.getItem(LOGIN_KEY)) {
    return ""
  }

  let acc = sessionStorage.getItem(CURRENT_ACCOUNT_KEY)
  if (!acc) {
    acc = _localStorageGet.apply(window.localStorage, [CURRENT_ACCOUNT_KEY])
  }
  return acc || ""
}

function getAccountKey(key) {
  const account = getAccount()
  return `${account ? `${account}:` : account}${key}`
}

function shouldIgnore(key) {
  return ["APP_VERSION", "oauth", "arrigoId", "APP_FRONTEND_VERSION", "cypress-login", "APP_LOADED_PACKAGES"].includes(
    key
  )
}

/**
 * Only affects localStorage.
 * Modified the key by prepending `account:`
 */
const setUpLocalStorage = () => {
  document.addEventListener("visibilitychange", function () {
    if (document.visibilityState === "visible" && !window.location.hash.startsWith("#/login")) {
      _localStorageSet.apply(window.localStorage, [CURRENT_ACCOUNT_KEY, getAccount()])
    }
  })

  Storage.prototype.setItem = function (key, value) {
    if (this !== window.localStorage || shouldIgnore(key)) {
      return _localStorageSet.apply(this, [key, value])
    }
    const accountKey = getAccountKey(key)
    if (this[accountKey] !== value) {
      return _localStorageSet.apply(this, [accountKey, value])
    }
  }
  Storage.prototype.getItem = function (key) {
    if (this !== window.localStorage || shouldIgnore(key)) {
      return _localStorageGet.apply(this, [key])
    }

    const accountKey = getAccountKey(key)
    return _localStorageGet.apply(this, [accountKey])
  }
  Storage.prototype.removeItem = function (key) {
    if (this !== window.localStorage || shouldIgnore(key)) {
      return _localStorageRemove.apply(this, [key])
    }

    const accountKey = getAccountKey(key)
    return _localStorageRemove.apply(this, [accountKey])
  }
}

export function checkLoginRoute() {
  if (window.location.hash.startsWith("#/login")) {
    sessionStorage.removeItem(CURRENT_ACCOUNT_KEY)
    sessionStorage.setItem(LOGIN_KEY, "1")
    return true
  } else {
    sessionStorage.removeItem(LOGIN_KEY)
  }
  return false
}

export function getAccountFromUrlOrLocalStorage() {
  return atob(window.location.hash.split("/")[1]).split("_").slice(0, -2).join("_") || getAccount()
}

let isBMS = true

export function localStorageIsBms() {
  return isBMS
}

/**
 * Checks if we are trying to visit another URL belonging to an account that's not the one we signed into this tab with.
 * If we are indeed trying to visit another URL, then change account and reload the window.
 * @return {boolean} `true` if we are on the wrong account, `false` otherwise.
 */
export function checkURLRouteMismatch() {
  if (!isBMS) {
    const account = getAccountFromUrlOrLocalStorage()
    const currentAccount = sessionStorage.getItem(CURRENT_ACCOUNT_KEY)

    if (account !== currentAccount) {
      // We probably tried to visit an URL belonging to a different account than the one we signed in to this tab with
      // Change account and reload page.
      sessionStorage.setItem(CURRENT_ACCOUNT_KEY, account)
      window.location.reload()
      return true
    }
  }
  return false
}

export async function trySetAccount() {
  try {
    const config = await API.retrieveLoginConfig()
    if (config?.service === "local") {
      return
    }
    isBMS = false

    // Not arrigo local, so override localstorage to support multiple accounts.
    setUpLocalStorage()

    if (!checkLoginRoute()) {
      const account = getAccountFromUrlOrLocalStorage()
      const currentAccount = sessionStorage.getItem(CURRENT_ACCOUNT_KEY)

      if (checkURLRouteMismatch()) {
        return
      }

      if (!currentAccount) {
        if (account) {
          sessionStorage.setItem(CURRENT_ACCOUNT_KEY, account)
          sessionStorage.removeItem(LOGIN_KEY)
        }
      }
    }
  } catch (_) {}
}
