class Network {
  callback
  registerNetworkChange(callback) {
    this.callback = callback
  }
  executeNetworkChange(state) {
    if (!this.callback) return
    this.callback.apply(this, [state])
  }
}

export default Network
